// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aloe-categories-js": () => import("./../../../src/pages/aloe-categories.js" /* webpackChunkName: "component---src-pages-aloe-categories-js" */),
  "component---src-pages-aloe-species-js": () => import("./../../../src/pages/aloe-species.js" /* webpackChunkName: "component---src-pages-aloe-species-js" */),
  "component---src-pages-aloes-by-bloom-time-js": () => import("./../../../src/pages/aloes-by-bloom-time.js" /* webpackChunkName: "component---src-pages-aloes-by-bloom-time-js" */),
  "component---src-pages-aloes-by-flower-color-js": () => import("./../../../src/pages/aloes-by-flower-color.js" /* webpackChunkName: "component---src-pages-aloes-by-flower-color-js" */),
  "component---src-pages-aloes-by-functional-use-js": () => import("./../../../src/pages/aloes-by-functional-use.js" /* webpackChunkName: "component---src-pages-aloes-by-functional-use-js" */),
  "component---src-pages-aloes-by-location-js": () => import("./../../../src/pages/aloes-by-location.js" /* webpackChunkName: "component---src-pages-aloes-by-location-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-aloe-profile-js": () => import("./../../../src/templates/aloe-profile.js" /* webpackChunkName: "component---src-templates-aloe-profile-js" */),
  "component---src-templates-aloes-by-a-category-js": () => import("./../../../src/templates/aloes_by_a_category.js" /* webpackChunkName: "component---src-templates-aloes-by-a-category-js" */),
  "component---src-templates-aloes-by-a-color-js": () => import("./../../../src/templates/aloes_by_a_color.js" /* webpackChunkName: "component---src-templates-aloes-by-a-color-js" */),
  "component---src-templates-aloes-by-a-season-js": () => import("./../../../src/templates/aloes_by_a_season.js" /* webpackChunkName: "component---src-templates-aloes-by-a-season-js" */),
  "component---src-templates-aloes-by-category-and-color-js": () => import("./../../../src/templates/aloes_by_category_and_color.js" /* webpackChunkName: "component---src-templates-aloes-by-category-and-color-js" */),
  "component---src-templates-aloes-by-color-and-season-js": () => import("./../../../src/templates/aloes_by_color_and_season.js" /* webpackChunkName: "component---src-templates-aloes-by-color-and-season-js" */),
  "component---src-templates-aloes-by-functional-use-js": () => import("./../../../src/templates/aloes_by_functional_use.js" /* webpackChunkName: "component---src-templates-aloes-by-functional-use-js" */),
  "component---src-templates-aloes-from-a-region-js": () => import("./../../../src/templates/aloes_from_a_region.js" /* webpackChunkName: "component---src-templates-aloes-from-a-region-js" */)
}

